<template>
    <div class="content-wrapper">
        <div class="page-header">
        <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex">
            <h4>
                <span class="font-weight-semibold">UGD Laboratorium</span>
            </h4>
            </div>
        </div>
        </div>
        <div class="content pt-0">
            <div class="">
                <div class="card">
                    <div class="bg-white card-header">
                        <div class="row align-items-center">
                            <div class="col-md-5">
                                <div class="form-row">
                                    <div class="col-md-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                                    
                                    <div class="col-md-8" v-if="isshowTable('aur_reg_date')">
                                        <div class="form-group mb-0 d-flex">
                                            <date-range-picker
                                                ref="picker"
                                                :locale-data="datePickerConfig.locale"
                                                :autoApply="datePickerConfig.autoApply"
                                                v-model="dateRange"
                                                :opens="'right'"
                                                :ranges="datePickerConfig.ranges"
                                                @update="updateValues"
                                            >
                                                <template v-slot:input="picker">
                                                    {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                                </template>
                                            </date-range-picker>
                                            <div class="input-group-append calendar-group">
                                                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-auto col-lg-7">
                                <div class="form-row justify-content-end">
                                    <!--
                                    <div class="col-md-5">
                                        <div class="lbl_status_pasien bg_red">
                                            <h3>
                                                <i class="icon-users2"></i>
                                                {{getQueue}} PASIEN ANTRI
                                            </h3>

                                        </div>
                                    </div>
                                    -->
                                    <div class="col-md-5">
                                        <div class="lbl_status_pasien bg_green">
                                            <h3>
                                                <i class="icon-user-check"></i>
                                                {{totalTindakan}} DALAM TINDAKAN PPA LAB
                                            </h3>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-auto">
                                        <a href="javascript:;" title="Download Laporan" v-b-tooltip.hover @click="openReport=true" data-toggle="modal" data-target="#FunnelData" class="lbl_status_pasien bg_blue">
                                            <h3><i class="icon-file-text3"></i></h3>
                                        </a>
                                    </div>
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header">
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-row">
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-md-auto">
                                        <div class="form-group mb-0">
                                            <button @click="openFilter = true" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Atur Kolom" v-b-tooltip.hover ><i class="icon-table2"></i></button>
                                        </div>
                                    </div>
                                    
                                    <div class="col-md-4">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status_lab" :options="Config.mr.configStatusRadiologiLab" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>

                                    <!--
                                    <div class="col-md-4" v-if="isshowTable('mpo_name')">
                                        <b-form-group class="mb-0">
                                            <v-select placeholder="Pilih Poli Layanan" @input="doFill" v-model="filter.poli" :options="mPoli" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                    -->
                                    <!--
                                    <div class="col-md-4">
                                        <b-form-group>
                                            <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.process_status" :options="Config.mr.configStatus" label="text" :reduce="v=>v.value"></v-select>
                                        </b-form-group>
                                    </div>
                                    -->
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                                    <input class="form-control"
                                        v-model="filter.search"
                                        @input="doSearch"
                                        placeholder="Ketik Nama/No. RM Pasien"
                                    />
                                    <div class="form-control-feedback">
                                        <i class="icon-search4 text-indigo"></i>
                                    </div>
                                        
                                    <b-button
                                        class="ml-1 d-inline-flex align-items-center"
                                        variant="outline-success"
                                        id="resetBtn"
                                        @click="doResetData()"
                                    >Reset</b-button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="table-responsive sticky-table">
                        <table class="table table-bordered table-striped table-hover table-sm patient-table dashboard-table">
                            <thead>
                                <tr>
                                
                                    <th>No.</th>
                                    <th>Triase</th>
                                    <th>Tindakan</th>
                                    <th v-if="isshowTable('aur_reg_code')">No. Kedatangan</th>
                                    <th v-if="isshowTable('ap_fullname')">Nama / No. KTP</th>
                                    <th>Diagnosa</th>
                                    <th v-if="isshowTable('aus_name')">Status</th>
                                    <th v-if="isshowTable('aur_reg_date')">Tanggal Daftar</th>
                                    <th v-if="isshowTable('ap_code')">No. RM</th>
                                    <th v-if="isshowTable('bu_full_name')">Nama Dokter</th>
                                    <th v-if="isshowTable('mcp_name')">Cara Bayar</th>
                                    <th v-if="isshowTable('ap_dob')">Tgl Lahir</th>
                                    <th v-if="isshowTable('ap_usia')">Usia</th>
                                    <th v-if="isshowTable('cg_label')">Jenis Kelamin</th>
                                    <th v-if="isshowTable('aur_is_lab')">Penunjang</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="!loadingTable" >
                                <template v-for="(v,k) in dataReg" >
                                    <tr :key="k" :class="v.aur_process_status_ppa_lab == 'QUEUE' ? 'table-info' : v.aur_process_status_ppa_lab == 'VOID' ? 'table-danger' : ''">
                                        <td>{{k+1}}</td>
                                        
                                        
                                        <template v-if="v.aur_triase_id">
                                            <td :class="'triase_'+v.color">
                                            <strong class="font-weight-semibold text-white">{{v.triase_type||"-"}}</strong>
                                            </td>
                                        </template>
                                        <template v-else>
                                        <td>
                                            <span class="font-weight-semibold text-warning-600">Belum Ada</span>
                                        </td>
                                        </template>

                                        <td>
                                            <a href="javascript:;" @click="showDataLab(v)" data-toggle="modal" data-target="#Fasttrack" data-popup="tooltip" title="Lihat Tindakan Lab Dokter" class="ml-1 btn btn-icon rounded-round btn-sm alpha-danger border-danger"><i class="icon-lab"></i></a>
                                        </td>

                                        
                                        
                                        
                                        <td v-if="isshowTable('aur_reg_code')">
                                        {{v.aur_reg_code||"-"}}
                                        <span v-if="v.ap_is_old_pasien !== 'Y'" class="text-primary">PASIEN BARU</span>
                                        <span v-else class="text-danger">PASIEN LAMA</span>                           </td>

                                        <td v-if="isshowTable('ap_fullname')">
                                        <template v-if="otherConditional(v)">
                                            <a href="javascript:;" @click="periksaPasien(v)" :class="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y'?'font-weight-semibold border-bottom text-danger':'font-weight-semibold border-bottom'"  v-b-tooltip.hover.right data-popup="tooltip" title="Periksa Pasien" > {{uppercaseWord(v.ap_fullname)||"-"}}</a>
                                        </template>
                                        <template v-else>
                                            <span :class="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y'?'text-danger':''">{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                                        </template>
                                        <br/>
                                        <small class="text-danger" style="font-size: 10px;" v-if="v.ap_fullname && isSameName(v.ap_fullname)">Nama Pasien Sama</small>

                                        <p>{{v.ap_nik||"-"}}</p>
                                        </td>

                                        <td>
                                                
                                            <a href="javascript:;" @click="lihatDiagnosa(v,k)" 
                                            v-b-tooltip.click.html="v.diagnosa" class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                                        </td>  

                                        <td v-if="isshowTable('aus_name')">
                                            <div class="badge badge-dark mb-1" v-if="v.aur_is_void == 'Y'">
                                                VOID
                                            </div>
                                            <template v-else>
                                                <!-- 2 == dalam pengkajian --> 
                                                <template v-if="v.aur_status == 2">
                                                    <div class="badge bg-warning mb-1" v-if="v.aur_is_primary_assesment_done != 'Y'">
                                                        Kajian Primer
                                                    </div>
                                                    
                                                    <div class="badge bg-warning mb-1" v-if="v.aur_is_secondary_assesment_done != 'Y'">
                                                        Kajian Sekunder
                                                    </div>

                                                    <div class="badge bg-info mb-1" v-if="v.aur_is_lab == 'Y' && v.aur_is_lab_done != 'Y'">
                                                        Laboratorium
                                                    </div>
                                                    <div class="badge bg-info mb-1" v-if="(v.aur_is_radiologi == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_radiologi_done != 'Y'">
                                                        Radiologi
                                                    </div>
                                                </template>
                                                <span v-else :class="`badge ${v.aus_color}`">{{uppercaseWord(v.aus_name)||'-'}}</span>
                                            </template>
                                            
                                            <p v-if="v.aur_is_verified_lab == 'Y'"
                                                v-b-tooltip.hovv-ber title="Hasil Kajian Lab" class="font-weight-semibold text-success">
                                                VERIFIED
                                            </p>
                                            
                                            <p v-if="v.aur_is_verified_lab !== 'Y'"
                                                v-b-tooltip.hovv-ber title="Hasil Kajian Lab" class="font-weight-semibold text-danger">
                                                UNVERIFIED
                                            </p>
                                            
                                            <a href="javascript:;" style="font-size: 12px;" v-if="(v.aur_is_lab == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_lab_done == 'Y'" class="font-weight-semibold border-bottom text-success"> Selesai Tindakan</a>

                                            <a href="javascript:;" style="font-size: 12px;" v-if="(v.aur_is_lab == 'Y' || v.aur_is_intra_penunjang == 'Y') && v.aur_is_lab_done !== 'Y'" class="font-weight-semibold border-bottom text-warning"> Pending </a>

                                        </td>

                                        <td v-if="isshowTable('aur_reg_date')">
                                        {{v.aur_reg_date | moment("DD MMM YYYY, HH:mm")}}</td>  
                                        

                                        <td v-if="isshowTable('ap_code')" v-b-tooltip.hover title="No Rekam Medis">{{uppercaseWord(v.ap_code)||"-"}}</td>
                                        <td v-if="isshowTable('bu_full_name')">{{uppercaseWord(v.bu_full_name)||"-"}}</td>
                                        <td v-if="isshowTable('mcp_name')">{{uppercaseWord(v.mcp_name)||"-"}}</td>
                                        <td v-if="isshowTable('ap_dob')">{{v.ap_dob | moment("DD MMM YYYY") }}</td>
                                        <td v-if="isshowTable('ap_usia')">
                                        {{v.ap_usia_with_ket||"-"}}
                                        <span class="text-success font-weight-semibold">{{v.ap_gol_usia||"-"}}</span>
                                        </td>
                                        <td v-if="isshowTable('cg_label')">{{v.cg_label}}</td>
                                        <td v-if="isshowTable('aur_is_lab')">
                                            <div v-if="v.aur_is_radiologi == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Radio</span>
                                            </div>
                                            <div v-if="v.aur_is_lab == 'Y'" class="d-flex align-items-center">
                                                <i class="icon-checkmark-circle text-success"></i>
                                                <span class="ml-1">Lab</span>
                                            </div>
                                                    
                                            <span v-if="v.aur_have_nilai_kritis_lab == 'Y' || v.aur_have_nilai_kritis_radiologi == 'Y'" class="badge bg-danger mb-1 mt-1" style="background-color: #640009 !important;">Kritis</span>

                                            <span v-if="v.aur_is_lab !== 'Y' && v.aur_is_radiologi !== 'Y'"> - </span>
                                        </td>
                                        <td>
                                            <div :id="`isAction${v.aur_id}`">
                                            <!--
                                            <a href="javascript:;" class="btn alpha-info border-info text-info-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)" v-if="moduleRole('request_edit') && v.aurm_id && !v.aurm_can_edit_by && !otherConditional(v)"><i class="icon-drag-left"></i></a>
                                            -->
                                            
                                            <a href="javascript:;" class="btn btn-icon rounded-round btn-sm alpha-info border-info" data-popup="tooltip" v-b-tooltip.hover title="Ajukan Perubahan Data" @click="requestEdit(v)" v-if="v.aurm_id && !v.aurm_can_edit_by && !otherConditional(v)"><i class="icon-drag-left"></i></a>

                                            <a href="javascript:;" v-if="otherConditional(v) && v.aurm_id" @click="redirect(v)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.right title="Edit" 
                                            ><i class="icon-pencil7"></i></a>
                                            
                                            <a href="javascript:;" v-if="v.aur_is_lab_done == 'Y' && v.aur_is_verified_lab !== 'Y'" @click="verifyData(v)" class="btn alpha-success border-success text-success-800 btn-icon rounded-round" data-popup="tooltip" v-b-tooltip.hover.right title="Verifikasi Hasil Laboratorium" 
                                            ><i class="icon-checkmark"></i></a>

                                            <span v-if="!(v.aurm_id) && (v.aur_is_lab_done == 'Y' && v.aur_is_verified_lab !== 'Y')"> - </span>
                                            
                                            </div>
                                            
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tbody v-if="loadingTable">
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                                <tr>
                                    <td colspan="99"><div class="skeletal-comp"></div></td>
                                </tr>
                            </tbody>
                            <tbody v-if="loadingInvinite"> 
                                        <tr>
                                            <td colspan="99"><div class="skeletal-comp"></div></td>
                                        </tr>
                            </tbody>
                            <tbody v-if="!(dataReg||[]).length && !loadingTable && dataReg">   
                                <tr>
                                    <th colspan="99" class="table-info text-center text-uppercase font-weight-semibold">
                                        Data Tidak Ditemukan
                                    </th>
                                </tr>                
                            </tbody>                
                        </table>
                        <div :class="(dataReg||[]).length && !loadingTable && dataReg ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
                            <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-left"></i>
                            </a>
                            <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
                            <i class="icon-chevron-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                
                <b-modal 
                    v-model="openFilter"
                    :title="'Filter Kolom'"
                    size="sm"
                    ok-title="Terapkan"
                    @ok="terapkan()"
                >
                    <div class="row">
                        <div class="col-md-12">
                        <div class="wrap_line">
                            <div class="form-check p-0">
                                <label class="form-check-label d-flex">
                                <b-form-checkbox @input="checkAll($event)" v-model="selectedAll" class="form-check-input-styled" name="radio-inline-left" />
                                Tampilkan Semua
                                </label>
                            </div>
                            <hr class="my-2">
                            <div class="pl-2">
                                <b-form-group>
                                    <b-form-checkbox-group
                                        id="checkbox-block"
                                        v-model="selectedFilter"
                                        :options="optionFilter"
                                        name="filter"
                                    ></b-form-checkbox-group>
                                </b-form-group>
                            </div>
                            </div>
                        </div>
                    </div>
                </b-modal>

                <b-modal 
                    v-model="openVerifikasiLab"
                    :title="'Konfirmasi Verifikasi Laboratorium'"
                    size="sm"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="text-center">
                        <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                            <i class="icon-warning2"></i>
                        </div>
                        <h6 class="mb-3">Apakah Anda yakin untuk memverifikasi data untuk pasien 
                        {{rowEdit.ap_fullname || "-"}} ?</h6>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" @click="openVerifikasiLab = false" class="btn btn-link" data-dismiss="modal">Batal</button>
                        <button type="submit" @click="konfirmasiVerifikasi" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Ya, Verifikasi</button>
                    </div>
                </b-modal>


                
                <b-modal 
                    v-model="detailData"
                    :title="'Data Tindakan Lab'"
                    size="lg"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="row g-3">
                            <template v-for="(v,k) in rowDetail.aupdl_data">
                                <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                    <div class="card shadow-0 border">
                                        <div class="card-header py-2 bg-light">
                                        <h6 class="card-title text-uppercase font-weight-semibold">
                                            {{v.head||"-"}}</h6>
                                        </div>
                                        <div class="card-body py-2">
                                        <template v-for="v1,k1 in (v.dubData||[])">
                                            <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)"
                                            :key="k1+'labdatas'">
                                            <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                            <template v-for="v2,k2 in (v1.data||[])">
                                                <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                                <i
                                                    class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                                <span class="align-middle">{{v2.text||"-"}}</span>
                                                <span v-if="v2.notes">, {{v2.notes}}</span>
                                                </div>
                                            </template>
                                            </div>
                                        </template>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="col-md-4 col-lg-3" v-if="rowDetail.aupdl_hasil_lainnya">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">Item
                                    Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                    <div>
                                        <i
                                        class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle">{{rowDetail.aupdl_hasil_lainnya||"-"}}</span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <span v-if="!selectedLabInput" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                    </div>
                </b-modal>

                

                <b-modal 
                    v-model="openPersetujuan"
                    :title="'Konfirmasi Ajukan Perubahan'"
                    size="sm"
                    body-class="p-2"
                    hide-footer
                >
                    <div class="modal-body">
                        <div class="text-center">
                        <div class="modal-shout-icon mb-3 d-inline-block alpha-warning text-warning rounded-circle">
                            <i class="icon-warning2"></i>
                        </div>
                        <h6 class="mb-3">Apakah Anda yakin untuk mengajukan perubahan pengkajian untuk pasien 
                        {{rowEdit.ap_fullname || "-"}} ?</h6>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" @click="openPersetujuan = false" class="btn btn-link" data-dismiss="modal">Batal</button>
                        <button type="submit" @click="konfirmasiEdit" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b> Ya, Ajukan</button>
                    </div>
                </b-modal>

                <validation-observer ref="VFormReport">        
                <b-modal 
                    v-model="openReport"
                    :title="'Download Laporan'"
                    size="sm"
                    ok-title="Unduh Laporan"
                    @ok="downloadReport()"
                >
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group mb-0 d-flex report-field">
                                <date-range-picker
                                    ref="picker"
                                    :locale-data="datePickerConfig.locale"
                                    :autoApply="datePickerConfig.autoApply"
                                    v-model="dateRangeReport"
                                    :opens="'right'"
                                    :ranges="datePickerConfig.ranges"
                                    @update="updateValues"
                                >
                                    <template v-slot:input="picker">
                                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                                    </template>
                                </date-range-picker>
                                <div class="input-group-append calendar-group">
                                    <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
                </validation-observer>        
                
                <b-modal 
                    v-model="loadingReport"
                    hide-footer
                    hide-header
                    size="sm"
                    no-close-on-backdrop
                    class="text-center"
                >
                    <div class="d-block text-center p-3 pt-3">
                        <b-spinner style="width: 3rem; height: 3rem;" variant="info" label="Large Spinner"></b-spinner>
                        <h5 class="text-info">Sedang Melakukan Download Laporan ....</h5>
                        <small>*Pengunduhan Laporan akan terjadi dalam waktu beberapa saat</small>
                    </div>
                </b-modal>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
// import Datepicker from 'vuejs-datepicker'

export default {
    extends: GlobalVue,
    data() {
        return {
            mPoli: [],
            dataReg: [],
            rowEdit: {},
            loadingInvinite: false,
            openPersetujuan: false,
            openPanggil: false,
            selectedAll: true,
            dateRange: {
                startDate: new Date(),
                endDate: new Date(),
            },
            totalData: 0,
            openFilter: false,
            getQueue: 0,
            getAction: 0,
            onScrollPoint: false,
            tableScrollPoint: null,
            canConfirm: false,
            
            optionFilter: [
                { text: 'No. Kedatangan', value: 'aur_reg_code' },
                { text: 'Nama', value: 'ap_fullname' },
                { text: 'Status', value: 'aus_name' },
                { text: 'Tanggal Daftar', value: 'aur_reg_date' },
                { text: 'No RM.', value: 'ap_code' },
                { text: 'Nama Dokter', value: 'bu_full_name' },
                { text: 'Cara Bayar', value: 'mcp_name' },
                { text: 'Tanggal Lahir', value: 'ap_dob' },
                { text: 'Usia', value: 'ap_usia' },
                { text: 'Jenis Kelamin', value: 'cg_label' },
                { text: 'Penunjang', value: 'aur_is_lab' },
            ],
            selectedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
            ],
            acceptedFilter: [
                'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
            ],
            datePickerConfig: {
                startDate: new Date(),
                endDate: new Date(),
                autoApply: true,
                ranges: {
                    'Hari Ini': [new Date(), new Date()],
                    '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                    '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
                },
                applyClass: 'btn-sm btn-primary',
                cancelClass: 'btn-sm btn-light',
                locale: {
                    applyLabel: 'Terapkan',
                    cancelLabel: 'Batal',
                    direction: 'ltr',
                    format: 'mm/dd/yyyy',
                    separator: ' - ',
                }
            },
            openVerifikasiLab: false,
            isPanggil: false,
            detailData: false,
            rowDetail: {},
            changeJadwal: false,
            totalTindakan: 0,

            openReport: false,
            loadingReport: false,
            dateRangeReport: {
                startDate: new Date(),
                endDate: new Date(),
            },
        }
    },
    computed: { 
        disabledDates() {
            return {
                to: new Date(moment().format('YYYY-MM-DD'))
            }
        },   
        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.dataGizi.asg_data||[]).length; i++){
                if(this.dataGizi.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        selectedLabInput() {
            let data = []
            for (let ik = 0; ik < (this.rowDetail.aupdl_data || []).length; ik++) {
                for (let jk = 0; jk < (this.rowDetail.aupdl_data[ik]['dubData'] || []).length; jk++) {
                    for (let kk = 0; kk < (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'] || []).length; kk++) {
                    if (this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['selected']) {
                        data.push(this.rowDetail.aupdl_data[ik]['dubData'][jk]['data'][kk]['text'])
                    }
                    }
                }
            }
            if (this.rowDetail.aupdl_hasil_lainnya) {
              data.push(this.rowDetail.aupdl_hasil_lainnya)
            }
            return data.join(", ")
        },
    },
  	components:{DateRangePicker
    //   Datepicker
    },
    methods: {
        downloadReport(){
            this.$refs['VFormReport'].validate().then(success=>{
                if(success){                
                    let startDate = moment(this.dateRangeReport.startDate).format('YYYY-MM-DD')
                    let endDate = moment(this.dateRangeReport.endDate).format('YYYY-MM-DD')
        
                    this.loadingReport = true
                    
                    
                    let data = {
                        exptype: 'xlsx', type: "export", startDate: startDate, endDate: endDate
                    }

			        let self = this
                    $.ajax({
                            type: "POST",
                            url: process.env.VUE_APP_API_URL + `/report/ReportLabList?token=IXs1029102asoaksoas102901290`,
                            data: data,
                            cache: false,
                            xhrFields:{
                                responseType: 'blob'
                            },
                            success: data => 
                            {
                                self.loadingOverlay = false
                                self.loadingReport = false
                                
                                var link = document.createElement('a')
                                link.href = window.URL.createObjectURL(data)
                                link.download = `Report Lab Nilai Kritis -${moment().format("YYYY-MM-DD")}.xlsx`
                                link.click()
                            },
                            fail: data => {
                                self.loadingOverlay = false
                                alert('Not downloaded')
                            }
                    })

                }
            })
        },
        konfirmasiVerifikasi(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'send-verif', id: this.rowEdit.aur_id, aur_is_verified_lab: 'Y'}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Kajian Berhasil DiVerifikasi`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet()
                }
                this.openVerifikasiLab = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openVerifikasiLab = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        isSameName(name){
            let index = this.dataReg.findIndex(x=>x.ap_fullname == name)
            let count = 0
            for (let i = 0; i < this.dataReg.length; i++) {
                if (this.dataReg[i].ap_fullname === name) {
                    count++
                }
            }
            if(count > 1){
                return "text-warning"
            }else{
                return ""
            }
        },
        lihatDiagnosa(v,k){
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'lihat-diagnosa', id: v.aur_id}}, 
                "POST"
            ).then(res=>{
                this.dataReg[k]['diagnosa'] = res.data        
            })
        },
        submitJadwalBaru(){
            console.log(this.rowEdit)
            this.$refs['VFormJadwal'].validate().then(success=>{
                if(success){
                    let data = this.rowEdit
                    data.type = "change-date-lab"
                    this.loadingOverlay = true   

                    Gen.apiRest(
                        "/do/"+this.modulePage,
                        {data: data}, 
                        "POST"
                    ).then(res=>{
                        this.$swal({
                            title: `Jadwal Lab Berhasil Diganti`,
                            icon: 'success',
                        })
                        this.loadingOverlay = false
                        this.changeJadwal = false
                        this.apiGet()
                    })

                }
            })
        },  
        changeJadwalLab(v){
            this.rowEdit = v
            this.rowEdit.aur_reg_ppa_lab_new = null
            this.changeJadwal = true
        },
        isShowHeadLab(i) {
            let isData = 0
            for (let j = 0; j < (this.rowDetail.aupdl_data[i]['dubData'] || []).length; j++) {
                for (let k = 0; k < (this.rowDetail.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                    if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                        isData += 1
                    }
                }
            }
            return isData
        },
        isShowHeadSubHeadLab(i, j) {
            let isData = 0
            for (let k = 0; k < (this.rowDetail.aupdl_data[i]['dubData'][j]['data'] || []).length; k++) {
                if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                    isData += 1
                }
            }
            return isData
        },
            
        isShowLab(i, j, k) {
            let isData = 0
            if (this.rowDetail.aupdl_data[i]['dubData'][j]['data'][k]['selected']) {
                isData += 1
            }
            return isData
        },
        showDataLab(v){
            this.loadingOverlay = true    
            Gen.apiRest(
                "/do/"+this.modulePage,
                {data:{type:'detail-lab', id: v.aur_id}}, 
                "POST"
            ).then(res=>{
                this.loadingOverlay = false
                this.rowDetail = res.data.dataDokter                
                this.detailData = true
            })
        },
        panggilAntrean(data){
            // to specific user
            data.to = this.uLab
            data.from = this.uLab
            this.$socket.emit('panggil_pasien', data)

            // to all pj
            data.to = "ALLPJ"
            data.from = this.uLab
            this.$socket.emit('panggil_pasien', data)
            
            this.isPanggil = true            
            setTimeout(()=>{
                this.isPanggil = false
            },5000)
        },
        verifyData(v){
            this.rowEdit = v
            this.openVerifikasiLab = true
        },
        doRefreshData(){
            this.apiGet(true)
            window.scrollTo(0,0)
        },

        isshowTable(name){
            return this.acceptedFilter.findIndex(x=>x == name) !== -1
        },

        terapkan(){
            if(!this.selectedFilter.length){
                return this.$swal({
                    icon: 'error',
                    title: 'Minimal harus ada 1 yang dipilih'
                })
            }
            this.acceptedFilter = this.selectedFilter
            localStorage.setItem(`kolumn_lab_ugd_${this.user.id}`,JSON.stringify(this.acceptedFilter))
            this.apiGet()
        },

        doPageOne(){
            this.filter.page = 1
        },
        
        doConvertDate(){
            this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
            this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
        },

        doSearch: _.debounce(function(){
            this.doPageOne()
            this.doFilter()
        },100),

        doFill(){
            this.doPageOne()
            this.doFilter()
        },
        updateValues(e){
            this.doConvertDate()
            this.doFill()
        },
        doResetData(){
            this.doReset()
            this.dateRange =  {
                startDate: new Date(),
                endDate: new Date(),
            }
            this.doConvertDate()
        },
        apiGet(isLoad = false){
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            this.doConvertDate()

            if((+this.$route.query.page||1) == 1 || isLoad){
                this.loadingTable = true
            }

            Gen.apiRest(
                "/get/"+this.modulePage, 
                {
                    params: Object.assign({
                        selectedFilter: this.selectedFilter.join(","),
                        page : this.filter.page,
                        startDate : this.filter.startDate,
                        endDate : this.filter.endDate,
                        byPassLevel: this.user.levelId == 1 ? this.uLab : null 
                    }, paramsQuery ||{})
                }
            ).then(res=>{
                let resp = res.data
                this.loadingTable = false
                this.loadingInvinite = false
                this.totalData = resp.dataReg.total

                if((+this.$route.query.page||1) !== 1){
                    let data = resp.dataReg.data
                    for(let i = 0; i < (data||[]).length; i++){
                        this.dataReg.push(data[i])
                    }
                }else{
                    this.dataReg = resp.dataReg.data
                    this.totalTindakan = resp.totalTindakan
                }   
            })
        },
        handleScroll(event){
            const scrollEl = document.querySelector('.sticky-table')
            const heightEl = scrollEl.querySelector('.table')
            

            if ((scrollEl.offsetHeight + scrollEl.scrollTop) >= (heightEl.offsetHeight * 0.98)) {
                if((this.dataReg||[]).length < this.totalData && !this.loadingInvinite && !this.loadingTable){
                    if(this.modulePage == 'UGDAssesmenLab'){
                        this.loadingInvinite = true
                        this.filter.page = (+this.$route.query.page||1) + 1

                        console.log("test ya")
                        this.$router.push({
                            name:this.modulePage,
                            query:_.clone(this.filter)
                        }).catch(()=>{})
                    }
                }
            }
            if (this.onScrollPoint && scrollEl.scrollTop == 0) {
                this.onScrollPoint = false
                window.removeEventListener('wheel', this.tableScrollFunction, false)
            }
        },
        requestEdit(v){
            this.rowEdit = v
            this.openPersetujuan = true
        },
        konfirmasiEdit(){
            this.loadingOverlay=true
            Gen.apiRest(
                "/do/"+'UGDRekamMedis',
                {data:{type:'send-request', id: this.rowEdit.aurm_id, aurm_can_edit_by: this.user.id}}, 
                "POST"
            ).then(()=>{
                this.loadingOverlay = false 
                this.$swal({
                    title: `Permintaan Berhasil Dikirim`,
                    icon: 'success',
                })
                
                if((+this.$route.query.page||1) != 1){
                    this.doResetData()
                }else{
                    this.apiGet()
                }
                this.openPersetujuan = false
                    
            }).catch(err=>{ 
                this.loadingOverlay = false 
                this.openPersetujuan = false
                if(err){
                    err.statusType = err.status
                    err.status = "error"
                    err.message = err.response.data.message
                    err.title = err.response.data.title
                    err.messageError = err.message
                }
                this.doSetAlertForm(err)
            })
        },
        otherConditional(v){
            return (moment().format('YYYY-MM-DD') <= this.to24Hours(v.aurm_created_date) || (v.aurm_is_approve == 'Y') || (!v.aurm_id))
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },


        momentDate(dates){
            let formatted = moment(dates).format("DD MMM YYYY")
            return formatted
        },
        checkAll(e){
            if(e){
                this.selectedFilter = [
                    'aur_reg_code','ap_fullname','aus_name','mpo_name','aur_reg_date','bu_full_name','mcp_name','ap_usia','ap_dob','cg_label','aur_is_lab','ap_code'
                ]
            }else{
                this.selectedFilter = []
            }
        },
        
        redirect(v){
            this.$router.push({ name: 'UGDFormLab', params: { pageSlug: v.ap_id, rmNo: v.aurm_id}, query: {regId: v.aur_id} }).catch(()=>{})
        
        },

        tableScrollFunction(e) {
           const responsiveTable = document.querySelector('.table-responsive')
           if(responsiveTable){
                let scrollDelta = e.deltaY
                let resScrollPos = responsiveTable.scrollTop + scrollDelta
                responsiveTable.scroll({
                    left: responsiveTable.scrollLeft,
                    top: resScrollPos,
                    behavior: 'smooth'
                })
            }
        },
        setSlide(){
            const responsiveTable = document.querySelector('.table-responsive')
            const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
            const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
            if(responsiveTableScroll){
                responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                Array.from(responsiveTableScrollActions).forEach(action => {
                    action.addEventListener('click', e => {
                        const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                        console.log(scrollAmount)
                        responsiveTable.scroll({
                            left: scrollAmount,
                            behavior: 'smooth'
                        })
                    })
                })
            }
        },
        
        
        windowTableScroller(e){
            const responsiveTable = document.querySelector('.table-responsive')
            if(responsiveTable){
                                const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
                if(responsiveTableScroll){
                    responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
                    this.tableScrollPoint = responsiveTable.getBoundingClientRect().top <= 64 ? (responsiveTable.offsetTop * 2) - 64 : null
                    const isTableScrollable = responsiveTable.scrollHeight > responsiveTable.clientHeight
                    if(isTableScrollable && responsiveTable.getBoundingClientRect().top <= 80 && this.tableScrollPoint){
                        this.onScrollPoint = true
                        document.documentElement.scroll(0, this.tableScrollPoint)
                        window.addEventListener('wheel', this.tableScrollFunction, false)
                    }
                }
            }
        },
        checkNullAction(cmp){
            return $(`#${cmp}`).is(':empty')
        },

        periksaPasien(v){
            if(this.user.levelId == 1){
                this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id, byPassLevel : this.uLab} }).catch(()=>{})
            }else{
                this.$router.push({ name: 'UGDAssesment', params: { pageSlug: v.ap_id }, query: {regId: v.aur_id} }).catch(()=>{})
            }
        },
    },
    mounted() {
        let filterColumn = JSON.parse(localStorage.getItem(`kolumn_lab_ugd_${this.user.id}`) || '[]')
        if(filterColumn.length){
            this.selectedFilter = filterColumn
            this.acceptedFilter = filterColumn
        }

        this.filter.page = 1
        
        if(this.user.mpo_id){
            this.filter.poli = 'ALL'
        }

        this.filter.status_lab = 'N'

        if(this.$route.query.page != 1){
            let url = {
                name:this.$route.name,
                params: (this.$route.params||{}),
                query:Object.assign({}, this.$route.query||{},_.clone(this.filter), {page:1})
            }
            this.$router.push(url).catch(()=>{})
        }else{
            this.apiGet()
        }
        
        if(this.filter.poli){
            this.filter.poli = +this.filter.poli
        }
        
        setTimeout(()=>{
            this.setSlide()
        },1000)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.addEventListener('scroll', this.handleScroll)
        
        window.addEventListener('scroll', this.windowTableScroller)
        this.onScrollPoint = false
    },  
    created () {
        // window.addEventListener('scroll', this.handleScroll)
    },
    unmounted () {
        // window.removeEventListener('scroll', this.handleScroll)
        const stickyTable = document.querySelector('.sticky-table')
        stickyTable.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener('scroll', this.windowTableScroller)
    },
    watch:{
        $route(){
           this.apiGet()
           setTimeout(()=>{
                if(this.isList){
                    this.setSlide()
                }
            },1000)
        },
        'onScrollPoint'(v){
            document.body.style.overflow = v ? 'hidden' : 'auto'
        }
    },
    filters: {
        date(val) {
           return val ? moment(val).format("D MMM YYYY") : ""
        }
    }
 
}

</script>

<style lang="scss" scoped>
  .custom-checkbox{
    margin-bottom: 5px !important;
  }
  .dashboard-table th, .dashboard-table td {
    white-space: normal !important;  
  }
</style>